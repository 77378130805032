import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { differenceInDays, parseISO } from "date-fns";
import fetchBlogPosts from "../utils/fetchBlogPosts";

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    fetchBlogPosts().then((posts) => {
      setPost(posts.find((p) => p.slug === slug));
    });
  }, [slug]);

  if (!post) return <p className="text-center text-navy mt-12">Loading...</p>;

  return (
    <>
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.excerpt} />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" content={post.image} />
        <meta
          property="og:url"
          content={`https://simplyknit.com/blog/${slug}`}
        />
        <meta property="og:site_name" content="Simply Knit" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.excerpt} />
        <meta name="twitter:image" content={post.image} />
      </Helmet>
      <div className="bg-gray-50 py-12">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold text-navy mb-6">{post.title}</h1>
            {post.image && (
              <img
                src={post.image}
                alt={post.title}
                className="w-full rounded-lg mb-8"
              />
            )}
            <div className="text-lg text-gray-800 leading-relaxed">
              {documentToReactComponents(post.content, {
                preserveWhitespace: true,
                renderNode: {
                  [INLINES.HYPERLINK]: ({ data }, children) => (
                    <a
                      href={data.uri}
                      className="dark:text-blue-500 hover:underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {children}
                    </a>
                  ),
                  [BLOCKS.HEADING_3]: (_, children) => (
                    <h3 className="text-lg font-semibold my-4">{children}</h3>
                  ),
                  [BLOCKS.HEADING_2]: (_, children) => (
                    <h2 className="text-2xl font-bold my-6">{children}</h2>
                  ),
                  [BLOCKS.HEADING_1]: (_, children) => (
                    <h1 className="text-3xl font-bold my-8">{children}</h1>
                  ),
                  [BLOCKS.PARAGRAPH]: (_, children) => (
                    <p className="text-gray-700 my-3 leading-relaxed">
                      {children}
                    </p>
                  ),
                },
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;
