import React from "react";
import { Link } from "react-router-dom";
import { differenceInDays, parseISO } from "date-fns";

const BlogCard = ({ title, excerpt, slug, image, publishedDate }) => {
  return (
    <article className="group overflow-hidden rounded-lg shadow-lg border border-gray-200">
      <Link to={`/blog/${slug}`}>
        <img
          src={image}
          alt={title}
          className="w-full h-48 object-cover group-hover:scale-105 transition-transform duration-300"
        />
        <div className="p-6">
          <h2 className="text-2xl font-bold text-navy group-hover:text-purple transition-colors duration-300">
            {title}
          </h2>
          <p className="text-gray-600 mt-3">{excerpt}</p>
        </div>
      </Link>
    </article>
  );
};

export default BlogCard;
